
import Swal from 'sweetalert2'
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import StudyCreationBase from '@/components/StudyCreation/StudyCreationBase.vue'
import StudyType from '@/components/StudyCreation/StudyType.vue'
import Viability from '@/components/StudyCreation/Viability.vue'
import ViabilityCalculator from '@/components/StudyCreation/ViabilityCalculator.vue'
import WatLabStep2 from '@/components/StudyCreation/WatLabStep2.vue'
import WatFocusStep2 from '@/components/StudyCreation/WatFocusStep2.vue'
import Button from '@/components/Button.vue'
import UsersOriginSelector from "@/components/StudyCreation/UsersOriginSelector.vue";
import ProgressModal from '@/components/StudyCreation/ProgressModal.vue'
import { ProductTypes, FocusSubProductTypes, userOrigin } from '@/utils/constants.ts'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name : 'NewStudy',
  components:{
    StudyCreationBase,
    Button,
    StudyType,
    Viability,
    ViabilityCalculator,
    WatLabStep2,
    ProgressModal,
    WatFocusStep2,
    UsersOriginSelector
  },
  setup() {
  const router = useRouter()
  const route = useRoute()

  return {
    router,
    route
  }
},
  props:{
    STEP:[String, Number],
    isStudyCreated: Boolean,
    usersOrigin:String
  },
  computed:{
    ...mapGetters([
      'surveySteps',
      'watlabSteps',
      'watFocusSteps',
      'clientCountry',
      'productsEnabled',
      'clientCreditsPlan'
    ]),
    watLabDescription():any{
      return '<p style="margin-bottom: 10px;">' + this.$t('suite_study_viability_watlab_desc') + '</p>'
    },
    showViabilityCalculator():any{
      return this.STEP == 1 && this.clientCreditsPlan.planType != 'MULTI_COMMUNITY'
    }
  },
  data(){
    return{
      type: '',
      studyData:{},
      surveyViabilityCalculator:{
        IR:100,
        estimatedMinutes:0,
        price:0,
        criteria:{
          testerNum:0,
          testersAvailable: 0
        },
        individualCriteria:[],
        quotas:[],
        desktopPercent:50,
        mobilePercent:50,
        country: 'es'
      },
      watLabViabilityCalculator:{
        duration:1,
        planName: 'GOLD',
        price:0
      },
      watFocusConfiguration:{
        studyName:'',
        publicTitle:'',
        type:FocusSubProductTypes.GROUP,
        usersOrigin:this.usersOrigin,
        country:'es',
        language:'es',
        sessionNum:1,
        sessionMinutes: 60,
        testerNum:5,
        screenShare: true,
        price:0
      },
      isMobile: false,
      showCalculatorMobile: false,
      showWatUxSelector: false,
      showUsersOriginSelector: false,
      viabilityError: false,
      viabilityErrorMessage:'',
      disabledStep3Button: true,
      quotaIndexWithError: -1,
      loadingViability: false,
      showProgress: false,
      studyName: '',
      crossedQuotasError: false,
      studyType: ''
    }
  },
  watch: {
    $route() {
      if(this.isWatFocus()){
          this.watFocusConfiguration.usersOrigin = (this.$route.params.usersOrigin == userOrigin.WAT) ? userOrigin.WAT : userOrigin.CLIENT 
      }
      this.initialMethods()
    }
  },
  methods:{
    async fetchTest(){
      if (this.route.params.testId != undefined) {
        await store.dispatch('getTest',{testId:this.route.params.testId}).then((data) => {
          this.studyName = data.test.title
          this.studyType = data.test.testCommercialType
          this.setStudyTypeFromData(this.studyType);
        })
      }
    },
    async fetchWatLab(){
      store.dispatch('fetchWatLabConfiguration',{contractId:this.route.params.id}).then((data) => {
        this.studyName = data.studyName
        this.watLabViabilityCalculator = {
          duration:data.duration,
          planName: data.planName,
          price:data.price
        }
      })
    },
    async fetchWatFocusConfig(){
      store.dispatch('fetchWatFocusConfiguration',{contractId:this.route.params.id}).then((data) => {
        if(data.sessionMinutes == null || data.sessionMinutes == undefined) data.sessionMinutes = 60
        this.watFocusConfiguration = data;
      })
    },
    async saveWatFocusOrigin(origin: any){
      this.watFocusConfiguration.usersOrigin = origin
      store.dispatch('saveWatFocusOrigin',{contractId:this.route.params.id, data:this.watFocusConfiguration}).then((data) => {
        this.router.push({name:'newstudy.step2WatFocusEdit',params:{usersOrigin:origin}})
      })
    },

    changeWatLabPlan(planName: any){
      this.watLabViabilityCalculator.planName = planName
      this.checkWatLabPrice()
    },
    changeWatLabDuration(duration: any){
      this.watLabViabilityCalculator.duration = duration
      this.checkWatLabPrice()
    },
    async checkWatLabPrice(){
      this.loadingViability = true
      await store.dispatch('checkWatLabPrice', this.watLabViabilityCalculator).then((data) => {
        this.watLabViabilityCalculator.price = data.price
        this.loadingViability = false
      })
    },
    async checkViabilityWatFocus(){
      this.loadingViability = true
      await store.dispatch('checkViabilityWatFocus', this.watFocusConfiguration).then((data) => {
        this.watFocusConfiguration.price = data.price
        this.loadingViability = false
      })
    },
    async checkViability(event: any){
      this.disabledStep3Button = false
      this.viabilityError = false
      this.crossedQuotasError = false
      this.quotaIndexWithError = -1
      const checkQuota = this.checkQuotaMatchTesterNum(event.quotas, event.criteria.testerNum, event.crossedQuotas)
      if(!this.checkZeroQuotas(event.quotas)){
        const index: any = checkQuota.quotaIndex
        this.quotaIndexWithError = index
        this.disabledStep3Button = true
        this.viabilityError = true
        this.viabilityErrorMessage = this.$t('suite_study_viability_error_quota')
        return
      }
      //const checkQuota = this.checkQuotaMatchTesterNum(event.quotas, event.criteria.testerNum, event.crossedQuotas)
      if(!checkQuota.success){
        this.disabledStep3Button = true
        this.viabilityError = true
        this.viabilityErrorMessage = this.$t('suite_study_viability_error_quota_total',{total:event.criteria.testerNum})
        const index: any = checkQuota.quotaIndex
        this.quotaIndexWithError = index
        this.crossedQuotasError = true
        return
      }
      this.loadingViability = true
      const serviceName = this.getViabilityServiceName()
      if(serviceName == "") {
        this.loadingViability = false
        return
      }
      await this.fetchTest()
      store.dispatch(serviceName, event)
      .then((data) => {
        this.viabilityError = false
        this.disabledStep3Button = false
        this.surveyViabilityCalculator = {
          IR:event.IR,
          price: data.price,
          estimatedMinutes:event.estimatedMinutes,
          criteria: data.criteria,
          individualCriteria: data.individualCriteria,
          quotas: data.quotas,
          desktopPercent: event.desktopPercent,
          mobilePercent: event.mobilePercent,
          country: event.country
        }
        const viability: any = this.$refs.Viability
        viability.updateQuotas(data.quotas)
        this.loadingViability = false
      })
      .catch((response)  => {
        this.viabilityError = true
        this.disabledStep3Button = true
        if(event.criteria.testerNum == null || event.criteria.testerNum == undefined || event.criteria.testerNum == "") this.viabilityErrorMessage = this.$t('suite_study_viability_error_participants')
        else this.viabilityErrorMessage = this.$t('suite_study_viability_error_criteria')
        this.loadingViability = false
      })
    },
    getViabilityServiceName(){
      if(this.isWatSurvey()) return 'checkViability'
      if(this.isWatUxQuanti()) return 'checkViabilityWatUxQuanti'
      if(this.isClickTest()) return 'checkViabilityClickTest'
      if(this.isWatUx()) return 'checkViabilityWatUx'
      if(this.isWatNeuro()) return 'checkViabilityWatNeuro'
      return ''      
    },
    getViabilityFetchServiceName(){
      if(this.isWatSurvey()) return 'fetchWatSurveyViability'
      if(this.isWatUxQuanti()) return 'fetchWatUxQuantiViability'
      if(this.isClickTest()) return 'fetchClickTestViability'
      if(this.isWatUx()) return 'fetchWatUxViability'
      if(this.isWatNeuro()) return 'fetchWatNeuroViability'
      if(this.isWatFocus()) return 'fetchWatFocusViability'
      return '' 
    },
    getStudyCreationServiceName(){
      if(this.isWatSurvey()) return 'createWATSurvey'
      if(this.isWatUxQuanti()) return 'createWATUxQuanti'
      if(this.isClickTest()) return 'createClickTest'
      if(this.isWatUx()) return 'createWATUx'
      if(this.isWatNeuro()) return 'createWATNeuro'
      return '' 
    },
    getStep3RouteForTest(){
      if(this.isWatSurvey()) return 'newstudy.step3Survey'
      if(this.isWatUxQuanti()) return 'newstudy.step3WatUxQuanti'
      if(this.isClickTest()) return 'newstudy.step3ClickTest'
      if(this.isWatUx()) return 'newstudy.step3WatUx'
      if(this.isWatNeuro()) return 'newstudy.step3WatNeuro'
      return '' 
    },
    checkZeroQuotas(quotas: any){
      for(let q of quotas){
        for(let sb of q.selectionBlocks){
          if(sb.testerNum < 1 || sb.testerNum == '') return false
        }
      }
      return true
    },
    checkQuotaMatchTesterNum(quotas: any, testerNum: any, crossedQuotas: any){
      let quotasTesterNum = 0
      if(quotas.length == 0) return { success: true }
      if(!crossedQuotas){
        let quotaIndex = 0
        for(let q of quotas){
          for(let sb of q.selectionBlocks){
            quotasTesterNum += sb.testerNum
          }
          if(testerNum != quotasTesterNum) return { success: false, quotaIndex:quotaIndex  }
          quotasTesterNum = 0
          quotaIndex++
        }
        return { success: true }
      }else{
        for(let q of quotas){
          for(let sb of q.selectionBlocks){
            quotasTesterNum += sb.testerNum
          }
        }
        return { success:testerNum == quotasTesterNum }
      }
    },
    beforeStep3SurveyOrUx(){
      if(this.disabledStep3Button) return

      const viability:any = this.$refs.Viability
      let data = viability.generateDataForViability()
      if(this.isWatSurveyEdit() || this.isWatUxQuantiEdit() || this.isWatUxEdit() || this.isWatNeuroEdit()){
        data.testName = this.studyName
        this.saveStep2SurveyOrUx(data)
      }
      else {
        let vm = this
        Swal.fire({
          title: vm.$t('suite_swal_study_name'),
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: vm.$t('suite_action_save'),
          cancelButtonText: vm.$t('suite_action_cancel'),
          showLoaderOnConfirm: true,
          preConfirm: (testName) => {
            data.testName = testName
            vm.saveStep2SurveyOrUx(data)
          }
        })
      }
    },
    beforeStep3WatLab(){
      let vm = this
      let data = {
        price: this.watLabViabilityCalculator.price,
        duration: this.watLabViabilityCalculator.duration,
        planName: this.watLabViabilityCalculator.planName,
        studyName: this.studyName,
        contractId: this.isWatLabEdit() ? this.route.params.id : null
      }
      if(this.isWatLabEdit()){
        this.saveStep2WatLab(data)
      }
      else {
        Swal.fire({
          title: vm.$t('suite_swal_study_name'),
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: vm.$t('suite_swal_study_name'),
          cancelButtonText: vm.$t('suite_action_cancel'),
          showLoaderOnConfirm: true,
          preConfirm: (studyName) => {
            data.studyName = studyName
            vm.saveStep2WatLab(data)
          }
        })
      }
    },
    beforeStep3WatFocus(){
      let data = {
        ...this.watFocusConfiguration,
        contractId: this.isWatFocusEdit() ? this.route.params.id : null,
        usersOrigin: this.isWatFocusEdit() ? this.watFocusConfiguration.usersOrigin : this.usersOrigin
      }
      if(data.type == FocusSubProductTypes.INTERVIEW) data.testerNum = 1
      if(data.studyName.length < 3 || data.publicTitle.length < 3) {
        this.viabilityError = true
        this.viabilityErrorMessage = this.$t('suite_study_error_watfocus')
        return
      }
      this.saveStep2Watfocus(data);
    },
    saveStep2WatLab(data: any){
      this.showProgress = true
      this.viabilityError = false
      this.viabilityErrorMessage = ''
      store.dispatch('createWatLab', data).then((data) => {
        this.showProgress = false
        this.router.push({name:'newstudy.step3WatLab', params:{id: data.contractId, testId:data.communityId}})
      }).catch((response)  => {
        this.viabilityError = true
        this.showProgress = false
        this.viabilityErrorMessage = this.$t('suite_study_viability_error')
      })
    },
    saveStep2SurveyOrUx(data: any){
      this.showProgress = true
      store.dispatch(this.getStudyCreationServiceName(), data)
      .then((data) => {
        this.showProgress = false
        this.router.push({name: this.getStep3RouteForTest(), params:{id: data.contractId, testId:data.testId}})
      })
      .catch((response)  => {
        this.viabilityError = true
        this.disabledStep3Button = true
        this.showProgress = false
        if (this.isWatUx()){
          this.viabilityErrorMessage = this.$t('suite_study_viability_error_watux_max_participants')
          return
        }
        this.viabilityErrorMessage = this.$t('suite_study_viability_error')
      })
    },
    saveStep2Watfocus(data: any){
      this.showProgress = true
      store.dispatch('createWatFocus', data)
      .then((data) => {
        this.showProgress = false
        this.router.push({name: 'newstudy.step3WatFocus', params:{id: data.contractId }})
      })
      .catch((response)  => {
        this.viabilityError = true
        this.disabledStep3Button = true
        this.showProgress = false
        this.viabilityErrorMessage = this.$t('suite_study_viability_error')
      })

    },
    resize(){
      if (window.innerWidth <=875) {
        this.isMobile = true
      }
      else{
        this.isMobile = false
        this.showCalculatorMobile = true
      }
    },
    goToQuota(event: any){
      const viability: any = this.$refs.Viability
      viability.goToQuota(event)
    },
    async fetchViability(){
      this.loadingViability = true
      await store.dispatch(this.getViabilityFetchServiceName(),{contractId:this.route.params.id}).then((data) => {
        if(this.isWatUxQuanti() || this.isWatUx()) data.country = this.clientCountry // Set default country 'es' for WAT_UX
        if(this.isWatUx()) data.IR = 100
        const viability: any = this.$refs.Viability
        viability.updateViability(data)
        this.loadingViability = false
      })
    },
    goToStep1(){
      if(this.isStudyCreated) this.router.push({name:'newstudy.step1Edit'})
      else this.router.push({name:'newstudy.step1'})
    },
    beforeStep2(type: any, origin: any = null){
      this.type = type
      if(this.isWatSurvey()) this.goToStep2Survey()
      else if(this.isWatLab()) this.goToStep2WatLab()
      else if(this.isWatUxQuanti()) this.goToStep2WatUxQuanti()
      else if(this.isClickTest()) this.goToStep2ClickTest()
      else if(this.isWatUx()) this.goToStep2WatUx()
      else if(this.isWatFocus()) this.goToStep2WatFocus(origin)
      else if(this.isWatNeuro()) this.goToStep2WatNeuro()
    },
    beforeSelectWatUxType(){
      this.showWatUxSelector = true
    },
    beforeSelectWatFocus(){
      this.showUsersOriginSelector = true
    },
    goToStep2Survey(){
      if(this.isStudyCreated) this.router.push({name:'newstudy.step2SurveyEdit'})
      else this.router.push({name:'newstudy.step2Survey'})
    },
    goToStep2WatLab(){
      if(this.isStudyCreated) this.router.push({name:'newstudy.step2WatlabEdit'})
      else this.router.push({name:'newstudy.step2Watlab'})
    },
    goToStep2WatUx(){
      if(this.isStudyCreated) this.router.push({name:'newstudy.step2WatUxEdit'})
      else this.router.push({name:'newstudy.step2WatUx'})
    },
    goToStep2WatUxQuanti(){
      if(this.isStudyCreated) this.router.push({name:'newstudy.step2WatUxQuantiEdit'})
      else this.router.push({name:'newstudy.step2WatUxQuanti'})
    },
    goToStep2ClickTest(){
      if(this.isStudyCreated) this.router.push({name:'newstudy.step2ClickTestEdit'})
      else this.router.push({name:'newstudy.step2ClickTest'})
    },
    goToStep2WatFocus(origin: any){
      if(this.isStudyCreated) this.saveWatFocusOrigin(origin)
      else if(origin == userOrigin.CLIENT )this.router.push({name:'newstudy.step2WatFocusClient', params:{usersOrigin:origin}})    
      else this.router.push({name:'newstudy.step2WatFocusWat', params:{usersOrigin:origin}})    
    },
    goToStep2WatNeuro(){
      if(this.isStudyCreated) this.router.push({name:'newstudy.step2WatNeuroEdit'})
      else this.router.push({name:'newstudy.step2WatNeuro'})   
    },
    isStep1(){
      return this.route.name == 'newstudy.step1'
    },
    isWatSurveyEdit(){
      return this.route.name == 'newstudy.step2SurveyEdit'
    },
    isWatSurvey(){
      return this.type == ProductTypes.WAT_SURVEY || this.isWatSurveyEdit() || this.route.name == 'newstudy.step2Survey'
    },
    isWatLabEdit(){
      return this.route.name == 'newstudy.step2WatLabEdit'
    },
    isWatLabStep2(){
      return this.isWatLabEdit() || this.route.name == 'newstudy.step2Watlab'
    },
    isWatLab(){
      return this.type == ProductTypes.WAT_LAB || this.isWatLabStep2()
    },
    isWatUx(){
      return this.type == ProductTypes.WAT_UX || this.isWatUxEdit() || this.route.name == 'newstudy.step2WatUx' || this.studyType == ProductTypes.WAT_UX
    },
    isWatFocus(){
      return this.type == ProductTypes.WAT_FOCUS || this.isWatFocusEdit() || this.route.name == 'newstudy.step2WatFocusClient' || this.route.name == 'newstudy.step2WatFocusWat'
    },
    isWatNeuro(){
      return this.type == ProductTypes.WAT_NEURO || this.isWatNeuroEdit() || this.route.name == 'newstudy.step2WatNeuro'
    },
    isWatUxQuanti(){
      return this.type == ProductTypes.WAT_UX_QUANTI || this.isWatUxQuantiEdit() || this.route.name == 'newstudy.step2WatUxQuanti' || this.studyType == ProductTypes.WAT_UX_QUANTI
    },
    isWatUxEdit(){
      return this.route.name == 'newstudy.step2WatUxEdit'
    },
    isWatFocusEdit(){
      return this.route.name == 'newstudy.step2WatFocusEdit'
    },
    isWatNeuroEdit(){
      return this.route.name == 'newstudy.step2WatNeuroEdit'
    },
    isWatUxQuantiEdit(){
      return this.route.name == 'newstudy.step2WatUxQuantiEdit'
    },
    isClickTest(){
      return this.type == ProductTypes.CLICK_TEST || this.isClickTestEdit() || this.route.name == 'newstudy.step2ClickTest' || this.studyType == ProductTypes.CLICK_TEST
    },
    isClickTestEdit(){
      return this.route.name == 'newstudy.step2ClickTestEdit'
    },
    setStudyType(){
        if(this.isWatSurvey() || this.isWatSurveyEdit()) {
            this.type = ProductTypes.WAT_SURVEY;
        }
        else if(this.isWatLab() || this.isWatLabEdit()) {
            this.type = ProductTypes.WAT_LAB;
        }
        else if(this.isWatUx() || this.isWatUxEdit()) {
            this.type = ProductTypes.WAT_UX;
        }
        else if(this.isWatUxQuanti() || this.isWatUxQuantiEdit()) {
            this.type = ProductTypes.WAT_UX_QUANTI;
        }
        else if(this.isClickTest() || this.isClickTestEdit()) {
            this.type = ProductTypes.CLICK_TEST;
        }
        else if(this.isWatNeuro() || this.isWatNeuroEdit()) {
            this.type = ProductTypes.WAT_NEURO;
        }
        else if(this.isWatFocus()){
          this.type = ProductTypes.WAT_FOCUS;
        }
    },
      setStudyTypeFromData(studyType:any) {
          if (studyType === ProductTypes.WAT_SURVEY) {
              this.type = ProductTypes.WAT_SURVEY;
          }
          else if (studyType === ProductTypes.WAT_LAB) {
              this.type = ProductTypes.WAT_LAB;
          }
          else if (studyType === ProductTypes.WAT_UX) {
              this.type = ProductTypes.WAT_UX;
          }
          else if (studyType === ProductTypes.WAT_UX_QUANTI) {
              this.type = ProductTypes.WAT_UX_QUANTI;
          }
          else if (studyType === ProductTypes.CLICK_TEST) {
              this.type = ProductTypes.CLICK_TEST;
          }
          else if (studyType === ProductTypes.WAT_NEURO) {
              this.type = ProductTypes.WAT_NEURO;
          }
      },
    windowMethods(){
      $("html, body").animate({ scrollTop: 0 }, 400);
      const vm = this
      this.resize()
      $(window).resize(function(){
         vm.resize()
      });
    },
    checkMultiCommunitiesPlanAndContinue(){
      if(this.checkSameProductType() && !this.isWatLabStep2() && this.isStep1()){
        this.beforeStep2(this.productsEnabled[0])
        return false
      }
      return true
    },
    checkSameProductType(){
      return this.productsEnabled.every((element: any) => element === this.productsEnabled[0]);
    },
      async initialMethods() {
          if (!this.checkMultiCommunitiesPlanAndContinue()) return;
          if (this.isWatSurveyEdit() || this.isWatUxEdit() || this.isWatUxQuantiEdit() || this.isClickTestEdit() || this.isWatNeuroEdit()) {
              await this.fetchViability();
              await this.fetchTest(); 
          } else {
              this.setStudyType();
          }

          if (this.isWatLabEdit()) await this.fetchWatLab();
          if (this.isWatLab()) await this.checkWatLabPrice();
          if (this.isWatFocusEdit()) await this.fetchWatFocusConfig();
          if(this.isWatFocus()) await this.checkViabilityWatFocus()
          
          if(this.isWatFocus() && this.isStep1() && this.isStudyCreated){
            this.beforeSelectWatFocus()
          }

          this.windowMethods();
      }
  },
  async created(){
    await this.setStudyType()
  },
  async mounted(){
    this.initialMethods()
  }
})
